<template>
  <div>
    <div style="margin: -45px -24px 0px">
      <a-card :bordered="false">
        <div class="titleBox">
          <label class="title">
            {{ details.storeName }}
            <span class="tag">{{ details.merchantTypeName }}</span>
          </label>
        </div>
        <div style="display: flex">
          <DescriptionList style="width: 80%">
            <div style="margin-left: 36px; width: 90%">
              <Description term="门店名称">
                <label>{{ details.facilityCode }} {{ details.facilityName }}</label>
              </Description>
              <Description term="档口编号">
                <label>{{ details.kitchenCode }}</label>
              </Description>
              <Description term="城市">
                <label>{{ details.cityName }}</label>
              </Description>
              <Description term="厨房品牌">
                <label>{{ details.brandName }}</label>
              </Description>
              <Description term="合同编号">
                  <ContractDetailLink :contractId="details.contractId" :contractCode="details.contractCode" />
              </Description>
              <Description term="证照类型">
                <label>{{ details.licenseType }}</label>
              </Description>
              <Description v-if="details.originalFacilityName" term="原门店名称" >
                <label>{{ details.originalFacilityName }}</label>
              </Description>
              <Description v-if="details.originalKitchenCode" term="原档口编号">
                <label>{{ details.originalKitchenCode }}</label>
              </Description>
            </div>
          </DescriptionList>
          <div class="status-panel">
            <div>
              <span class="staff-names">入住进度：</span>
              <span :class="this.getStatusbg()">{{ details.processStatusName }}</span>
            </div>
            <div class="staff-names">处理人：{{ details.staffNames }}</div>
            <div class="staff-names">总耗时：{{ details.consumeTime }}</div>
          </div>
        </div>
      </a-card>
    </div>
    <div style="margin-bottom: 24px; margin: -24px -24px 24px">
      <a-card
        style="width: 100%"
        :bordered="false"
        :tabList="tabList"
        :activeTabKey="tabKey"
        @tabChange="(key) => onTabChange(key)"
      ></a-card>
    </div>
    <div style="min-width: 1000px">
      <div style="margin-left: 16px; margin-bottom: 24px">
        <a-card
          v-if="tabKey === 'info'"
          :bordered="false"
          style="margin-left: -16px"
        >
          <DescriptionList title="场地交接存档" size="large" class="info-area">
            <div style="margin-top: 16px">
              <Description v-for="(item, index) in handover.pics" :key="item.id" :term="index" >
                <label class="blueColor">
                  <a v-if="item && item.length > 0" @click="showImageModal(item)" >查看图片</a>
                </label>
              </Description>
              <Description term="场地交接日">
                <label>{{ dateFormat(handover.kitchenHandoverDate) }}</label>
              </Description>
              <Description term="水电接驳完成日">
                <label>{{ dateFormat(handover.utilitiesRetrofitCompletedDate) }}</label>
              </Description>
            </div>
          </DescriptionList>

          <DescriptionList title="商户入场设备" size="large" class="info-area">
            <div style="margin-top: 16px">
              <a-table
                rowKey="id"
                :pagination="deviceListPagination"
                :columns="deviceListColumns"
                :dataSource="deviceList"
                :scroll="{ x: 1200 }"
                class="table-wrapper"
              >
                <span slot="handoverPic" slot-scope="record" class="blueColor">
                  <a v-if="record.detailPics && record.detailPics.length > 0" @click="showImageModal(record.detailPics)">查看图片</a>
                </span>
                <span slot="trainPics" slot-scope="record" class="blueColor">
                  <a v-if=" record.certificatePics && record.certificatePics.length > 0" @click="showImageModal(record.certificatePics)">查看图片</a>
                </span>
              </a-table>
            </div>

            <Description term="设备现场摆放照片">
              <label class="blueColor">
                <a v-if=" devicePics && devicePics.length > 0" @click="showImageModal(devicePics)">查看图片</a>
              </label>
            </Description>

          </DescriptionList>

          <DescriptionList title="员工信息" size="large" class="info-area">
            <div style="margin-top: 16px">
              <a-table
                rowKey="id"
                :pagination="staffListPagination"
                :columns="staffListColumns"
                :dataSource="staffList"
                :scroll="{ x: 800 }"
                class="table-wrapper"
              >
                <span
                  slot="healthCertificateDate"
                  slot-scope="record"
                  class="blueColor"
                  >
                  {{ dateFormat(record.healthCertificateStartDate) }} - {{ dateFormat(record.healthCertificateDate) }}
                </span>
                <span slot="idCardPic" slot-scope="record" class="blueColor">
                  <a
                    v-if="record.idCardFrontPic && record.idCardBackPic"
                    @click="
                      showImageModal([
                        record.idCardFrontPic,
                        record.idCardBackPic,
                      ])
                    "
                    >查看图片</a
                  >
                </span>
                <span
                  slot="healthCertificatePic"
                  slot-scope="record"
                  class="blueColor"
                >
                  <a
                    v-if="
                      record.healthCertificateFrontPic &&
                      record.healthCertificateBackPic
                    "
                    @click="
                      showImageModal([
                        record.healthCertificateFrontPic,
                        record.healthCertificateBackPic,
                      ])
                    "
                    >查看图片</a
                  >
                </span>
              </a-table>
            </div>
          </DescriptionList>
          <DescriptionList title="上线建店" size="large" class="info-area">
            <div style="margin-top: 16px">
              <Description term="平台建店时间">
                <label>{{ online.onlineTime }}</label>
              </Description>
              <Description term="店铺名称">
                <label class="blueColor">
                  <a @click="gotoShopDetail(online.storeId)">{{
                    online.storeName
                  }}</a>
                </label>
              </Description>
              <Description term="建店成功短信/后台截图">
                <label class="blueColor">
                  <a
                    v-if="online.pics && online.pics.length > 0"
                    @click="showImageModal(online.pics)"
                    >查看图片</a
                  >
                </label>
              </Description>
            </div>
          </DescriptionList>
          <DescriptionList title="商户开业" size="large" class="info-area">
            <div style="margin-top: 16px">
              <Description term="商户开业日期">
                <label>{{ open.actualOccupancyDate }}</label>
              </Description>
              <Description term="商户计租开始日期">
                <label>{{ open.actualRentalDate }}</label>
              </Description>
            </div>
          </DescriptionList>
          <DescriptionList
            title="商户办证"
            v-if="license"
            size="large"
            class="info-area"
          >
            <div style="margin-top: 16px">
              <Description term="食品安全许可证办理完成时间">
                <label>{{ license.foodTime }}</label>
              </Description>
              <Description term="营业执照办理完成时间">
                <label>{{ license.bizTime }}</label>
              </Description>
              <Description term="食品安全许可证">
                <label class="blueColor">
                  <a
                    v-if="license.foodPic && license.foodPic.length > 0"
                    @click="showImageModal(license.foodPic)"
                    >查看图片</a
                  >
                </label>
              </Description>
              <Description term="营业执照">
                <label class="blueColor">
                  <a
                    v-if="license.bizPic && license.bizPic.length > 0"
                    @click="showImageModal(license.bizPic)"
                    >查看图片</a
                  >
                </label>
              </Description>
            </div>
          </DescriptionList>
          <DescriptionList title="商户培训" size="large" class="info-area">
            <div style="margin-top: 16px">
              <Description term="培训信息">
                <label>{{ getTrainType }}</label>
              </Description>
              <Description term="商户培训图片">
                <label class="blueColor">
                  <a
                    v-if="train.trainPics && train.trainPics.length > 0"
                    @click="showImageModal(train.trainPics)"
                    >查看图片</a
                  >
                </label>
              </Description>
            </div>
          </DescriptionList>
        </a-card>
        <a-card
          v-if="tabKey === 'progress'"
          :bordered="false"
          style="margin-left: -16px"
        >
          <div class="desc-title">开始时间：{{ enteringStartTime }}</div>
          <div v-for="(item, index) in progressList" :key="index">
            <div class="desc-title" style="display: flex">
              <div style="width: 15%">{{ item.nodeName }}</div>
              <div style="width: 25%">完成人：{{ item.staff }}</div>
              <div style="width: 60%">完成时间：{{ item.finishTime }}</div>
            </div>
          </div>
        </a-card>
      </div>
    </div>
    <ImagePreviewModal
      v-if="imageModalOpen"
      :imageUrls="imageUrls"
      :closeAction="hideImageModal"
    />
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Bus from "@/bus";
  
import { defaultPagination } from "@/utils/pagination";
import DescriptionList from "@/components/DescriptionList";
import ImagePreviewModal from "@/components/ImagePreviewModal";
import ContractDetailLink from "@/components/link/ContractDetailLink";

const { Description } = DescriptionList;

export default {
  name: "tenantEnteringDetail",
  components: {
    DescriptionList,
    Description,
    ImagePreviewModal,
    ContractDetailLink,
  },
  data() {
    return {
      detailId: this.$route.query.id,
      tabKey: "info",
      tabList: [
        {
          key: "info",
          tab: "入驻信息",
        },
        {
          key: "progress",
          tab: "完成进度",
        },
      ],
      // 从api获取的详情数据
      details: {},
      // 从api获取的“完成进度”tab页数据
      progressDetails: {},
      // 商户信息
      tenant: {},
      deviceList: [],
      devicePics: [],
      deviceListPagination: defaultPagination(() => {}),
      // 商户入场设备表头
      deviceListColumns: [
        {
          title: "设备名称",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "是否新设备",
          dataIndex: "isNew",
          key: "isNew",
        },
        {
          title: "设备规格",
          dataIndex: "specification",
          key: "specification",
        },
        {
          title: "设备数量",
          dataIndex: "num",
          key: "num",
        },
        {
          title: "电压 (V)",
          dataIndex: "voltage",
          key: "voltage",
        },
        {
          title: "功率 (Kw)",
          dataIndex: "power",
          key: "power",
        },
        {
          title: "燃气量 (m³)",
          dataIndex: "gasQuantity",
          key: "gasQuantity",
        },
        {
          title: "设备图片",
          scopedSlots: { customRender: "handoverPic" },
          key: "handoverPic",
        },
        {
          title: "设备合格证或资质图片",
          scopedSlots: { customRender: "trainPics" },
          key: "trainPics",
          width: 180,
        },
        {
          title: "备注",
          dataIndex: "remark",
          key: "remark",
        },
      ],
      handover: {},
      staffList: [],
      staffListPagination: defaultPagination(() => {}),
      // 员工信息表头
      staffListColumns: [
        {
          title: "姓名",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "性别",
          dataIndex: "gender",
          key: "gender",
        },
        {
          title: "职位",
          dataIndex: "position",
          key: "position",
        },
        {
          title: "户籍",
          dataIndex: "region",
          key: "region",
        },
        {
          title: "身份证号",
          dataIndex: "idCard",
          key: "idCard",
        },
        {
          title: "健康证起止日期",
          scopedSlots: { customRender: "healthCertificateDate" },
          key: "healthCertificateDate",
          width: 150,
        },
        {
          title: "联系方式",
          dataIndex: "phone",
          key: "phone",
        },
        {
          title: "身份证照片",
          key: "idCardPic",
          scopedSlots: { customRender: "idCardPic" },
        },
        {
          title: "健康证照片",
          scopedSlots: { customRender: "healthCertificatePic" },
          key: "healthCertificatePic",
        },
        {
          title: "备注",
          dataIndex: "remark",
          key: "remark",
        },
      ],
      // 水电接驳
      waterEle: {},
      // 上线建店
      online: {},
      // 商户开业
      open: {},
      // 商户办证
      license: {},
      // 商户培训
      train: {},
      // 完成进度列表
      progressList: [],
      // 进度开始时间
      enteringStartTime: "",
      // 是否展示图片弹窗
      imageModalOpen: false,
      // 此页面的图片弹窗展示的图片url
      imageUrls: [],
    };
  },
  computed: {
    dateFormat(value) {
      return (value) => {
        return value && moment(value).format("YYYY-MM-DD");
      };
    },
    getTrainType() {
      const types = this.train.itemIds;
      const trainType = [
        { id: 1, name: "食品安全培训" },
        { id: 2, name: "消防安全培训" },
        { id: 3, name: "外卖基础培训" },
        { id: 4, name: "星厨闪电培训" },
        { id: 5, name: "规章制度培训" },
      ];
      return (
        types &&
        types.map((t) => trainType.find((i) => i.id === t).name).join("，")
      );
    },
  },
  created() {
    this.init();
    Bus.$on("getTarget", (target) => {
      this.init();
    });
  },
  methods: {
    init() {
      axios({
        url:   this.$baseUrl + "merchant/entering/detail",
        method: "GET",
        params: {
          id: this.detailId,
        },
      }).then((res) => {
        if (res.data.success) {
          this.details = res.data.obj;
          if (this.tabKey === "progress") {
            this.getProgressTab();
          } else {
            this.getInfoTab();
          }
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
      });
    },
    getInfoTab() {
      this.tabKey = "info";
      axios({
        url:   this.$baseUrl + "merchant/entering/processDetail",
        method: "GET",
        params: {
          id: this.detailId,
        },
      }).then((res) => {
        if (res.data.success) {
          this.tenant = res.data.obj.tenant;
          this.deviceList = res.data.obj.deviceList;
          this.devicePics = res.data.obj.devicePics;
          this.handover = res.data.obj.handover;
          this.staffList = res.data.obj.staffList;
          this.waterEle = res.data.obj.waterEle;
          this.online = res.data.obj.online;
          this.open = res.data.obj.open;
          this.license = res.data.obj.license;
          this.train = res.data.obj.train;
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
      });
    },
    getProgressTab() {
      this.tabKey = "progress";
      axios({
        url:   this.$baseUrl + "merchant/entering/progressList",
        method: "GET",
        params: {
          id: this.detailId,
        },
      }).then((res) => {
        if (res.data.success) {
          this.progressList = res.data.obj.instanceList;
          this.enteringStartTime = res.data.obj.startTime;
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
      });
    },
    // 切换tab页
    onTabChange(toTabKey) {
      if (toTabKey === "info") {
        this.getInfoTab();
      } else if (toTabKey === "progress") {
        this.getProgressTab();
      }
    },
    // 跳转到店铺详情页
    gotoShopDetail(id) {
      this.$router.push({
        path: "/role/shopList/shopDetail",
        query: { id: id },
      });
    },
    showImageModal(imageUrl) {
      let images = [];
      if (!Array.isArray(imageUrl)) {
        images = [imageUrl];
      } else {
        images = imageUrl;
      }
      this.imageUrls = images;
      this.imageModalOpen = true;
    },
    hideImageModal() {
      this.imageUrls = [];
      this.imageModalOpen = false;
    },
    getStatusbg() {
      let status = "entering-status ";
      if (this.details.processStatusName === "未开始") {
        return status + "gray-status-color";
      } else if (this.details.processStatusName === "进行中") {
        return status + "yellow-status-color";
      } else if (this.details.processStatusName === "已完成") {
        return status + "green-status-color";
      }
    },
  },
};
</script>

<style scoped>
.titleBoxL {
  padding-left: 2.5em !important;
}
.titleBox {
  padding: 1em;
}
.title {
  font-size: 1.5em;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5em;
}
.tag {
  font-size: 0.6em;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.85);
  line-height: 0.6em;
  background-color: rgba(44, 136, 203, 1);
  color: #fff;
  padding: 0.2em 0.4em;
  margin-left: 1em;
  border-radius: 0.4em;
}
.entering-status {
  font-size: 20px;
  color: #fff;
  border-radius: 4px;
  font-weight: bold;
}
.staff-names {
  font-size: 16px;
  line-height: 30px;
}
.info-area {
  margin: 0px 0px 36px 0px;
}
.table-wrapper {
  margin-left: 16px;
  margin-right: 16px;
}
.image-wrapper {
  display: flex;
  flex-direction: column;
}
.desc-title {
  font-size: 16px;
  font-weight: bold;
  height: 50px;
}
.status-panel {
  border: 1px solid;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  width: 20%;
}
</style>
